import { Image } from "@chakra-ui/react";
import * as React from "react";
import useTranslation from "next-translate/useTranslation";

export const Logo = (props) => {
  const { t, lang } = useTranslation("common");
  return (
    <Image
      {...props}
      src='/assets/images/logo.svg'
      width={props.width ? props.width : null}
      alt={`${t("common:appName")} logo`}
      pb='8'
    />
  );
};
