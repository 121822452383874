import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";

export const Card = (props: BoxProps) => (
  <Box
    bg={useColorModeValue("white", "gray.800")}
    boxShadow={"sm"}
    rounded={{ base: "lg", md: "2xl" }}
    p='8'
    {...props}
  />
);

export const FocusCard = (props: BoxProps) => (
  <Box
    bg={useColorModeValue("white", "gray.800")}
    rounded={{ md: "2xl" }}
    p='8'
    {...props}
  />
);

export const CalendarCard = (props: FlexProps) => (
  <Flex
    bg={useColorModeValue("white", "gray.800")}
    boxShadow='sm'
    rounded={{ md: "2xl" }}
    p='8'
    height={"100%"}
    {...props}
  />
);

export const CalendarCardFuture = (props: FlexProps) => (
  <Flex
    bg={useColorModeValue("gray.100", "gray.600")}
    boxShadow='sm'
    rounded={{ md: "2xl" }}
    p='8'
    {...props}
  />
);

export const CalendarCardNotes = (props: FlexProps) => (
  <Flex
    bg={useColorModeValue("yellow.100", "yellow.400")}
    boxShadow='sm'
    rounded={{ md: "2xl" }}
    p='8'
    height={"100%"}
    maxH={"100%"}
    {...props}
  />
);
