import React, {
  useState,
  useEffect,
  Context,
  createContext,
  useContext,
} from "react";
import { InferGetServerSidePropsType, GetServerSidePropsContext } from "next";
import nookies from "nookies";
import { useAuth } from "../lib/auth";
import { getBrowserLocales } from "../utils/usersettings";
import { Header } from "../components/Header";
import {
  Flex,
  Skeleton,
  Box,
  Grid,
  GridItem,
  HStack,
  VStack,
  Text,
  Heading,
  Spinner,
  Button,
  IconButton,
  Spacer,
  useColorModeValue as mode,
  Center,
} from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { PageHeader } from "./PageHeader";

interface PageWrapperProps {
  title: string;
  showHeader?: boolean;
  children?: React.ReactNode;
}

const PageWrapper = (props: PageWrapperProps) => {
  const { title, showHeader } = props;
  const { auth, organization, signOut, dbUser } = useAuth();
  const [loadingUser, setLoadingUser] = useState(true);
  const { t, lang } = useTranslation("common");

  useEffect(() => {
    if (!auth) return;
    if (auth && organization && dbUser) {
      setLoadingUser(false);
    }
  }, [auth, organization, dbUser]);

  return (
    <>
      <PageHeader title={"TGL Wellbeing Buddy -- " + title} follow={false} />
      {showHeader ? (
        <Flex
          direction='column'
          bg={mode("gray.50", "gray.800")}
          minHeight='100vh'
          pb={16}
        >
          {!loadingUser ? (
            <>
              <Header auth={auth} user={dbUser} signOut={signOut} />
              {props.children}
            </>
          ) : (
            <Center height='100vh'>
              <VStack>
                <Spinner
                  my='auto'
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />
                <Text>{t("common:loading")}</Text>
              </VStack>
            </Center>
          )}
        </Flex>
      ) : (
        props.children
      )}
    </>
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  try {
    const cookies = nookies.get(context);

    return {
      props: { token: cookies.token },
    };
  } catch (err) {
    // redirect to the login page
    context.res.writeHead(302, { Location: "/signin" });
    context.res.end();
    // `as never` prevents inference issues with InferGetServerSidePropsType.
    return { props: {} as never };
  }
};

export default PageWrapper;
