import React, { useState, useEffect } from "react";

import {
  Box,
  Flex,
  HStack,
  Center,
  CenterProps,
  useBoolean,
  useBreakpointValue,
  Avatar,
  Menu,
  MenuItem,
  MenuList,
  Text,
  Spacer,
  useMenuButton,
  UseMenuButtonProps,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { HiOutlineMenu, HiX, HiOutlineExternalLink } from "react-icons/hi";
import { FaBell } from "react-icons/fa";
import { Logo } from "./Logo";
import {
  HiDuplicate,
  HiMail,
  HiRefresh,
  HiTemplate,
  HiViewGrid,
} from "react-icons/hi";
import { string } from "yup";
import router, { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

interface MobileHamburgerMenuProps {
  onClick?: VoidFunction;
  isOpen: boolean;
}

export const MobileHamburgerMenu = (props: MobileHamburgerMenuProps) => {
  const { onClick, isOpen } = props;
  const { t, lang } = useTranslation("common");
  return (
    <Box
      ms='-4'
      minW={{ base: "12", lg: "76px" }}
      display={{ lg: "none" }}
      color={"gray.700"}
    >
      <Box as='button' onClick={onClick} p='2' fontSize='xl'>
        <Box aria-hidden as={isOpen ? HiX : HiOutlineMenu} />
        <Box srOnly>
          {isOpen
            ? t("common:header.general.close")
            : t("common:header.general.open")}
        </Box>
      </Box>
    </Box>
  );
};

interface NavItemProps {
  href?: string;
  active?: boolean;
  label: string;
}

interface DesktopNavItemProps extends NavItemProps {}

const DesktopNavItem = (props: DesktopNavItemProps) => {
  const { label, href = "#", active } = props;
  const { t, lang } = useTranslation("common");
  return (
    <HStack
      as='a'
      href={href}
      aria-current={active ? "page" : undefined}
      spacing='2'
      px='4'
      py='2'
      rounded='md'
      transition='all 0.2s'
      color={mode("gray.700", "white")}
      _hover={{ bg: "gray.100" }}
      _activeLink={{ color: "yellow.500" }}
    >
      <Box fontWeight='semibold'>{label}</Box>
    </HStack>
  );
};

const MobileNavItem = (props: NavItemProps) => {
  const { label, href = "#", active } = props;
  const { t, lang } = useTranslation("common");
  return (
    <Box
      as='a'
      display='block'
      href={href}
      px='3'
      py='3'
      rounded='md'
      fontWeight='semibold'
      aria-current={active ? "page" : undefined}
      _hover={{ bg: "whiteAlpha.200" }}
      _activeLink={{ bg: "blackAlpha.300", color: "white" }}
    >
      {label}
    </Box>
  );
};

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
};

const MobileNavMenu = (props: { isOpen?: boolean }) => {
  const { isOpen } = props;
  const { t, lang } = useTranslation("common");
  return (
    <Flex
      hidden={!isOpen}
      as='nav'
      direction='column'
      bg='gray.700'
      position='fixed'
      height='calc(100vh - 4rem)'
      w={{ base: "100vw", md: "100vw" }}
      top='20'
      insetX='0'
      zIndex={10}
    >
      <Box pt='8' px='4'>
        <Box onClick={() => router.push("/wins")}>
          <NavItem.Mobile
            active={router.pathname == "/wins"}
            label={t("common:header.items.wins")}
          />
        </Box>
        <Box onClick={() => router.push("/focus")}>
          <NavItem.Mobile
            active={router.pathname == "/focus"}
            label={t("common:header.items.focus")}
          />
        </Box>
      </Box>
    </Flex>
  );
};

const DesktopNavMenu = () => {
  const { t, lang } = useTranslation("common");
  return (
    <HStack
      flex='1'
      display={{ base: "none", lg: "flex" }}
      flexDirection={"row-reverse"}
      pr={8}
    >
      <Box onClick={() => router.push("/focus")}>
        <NavItem.Desktop
          active={router.pathname == "/focus"}
          label={t("common:header.items.focus")}
        />
      </Box>

      <Box onClick={() => router.push("/wins")}>
        <NavItem.Desktop
          active={router.pathname == "/wins"}
          label={t("common:header.items.wins")}
        />
      </Box>
    </HStack>
  );
};

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
};

const NotificationBadge = (props: CenterProps) => (
  <Center
    bg='red.500'
    fontSize='xs'
    fontWeight='bold'
    position='absolute'
    rounded='full'
    textAlign='center'
    top='-2px'
    insetEnd='0'
    w='18px'
    h='18px'
    {...props}
  />
);

export const Notification = (props: CenterProps) => (
  <Center
    as='button'
    outline='0'
    w='8'
    h='8'
    position='relative'
    rounded='md'
    _hover={{ bg: "whiteAlpha.200" }}
    _focus={{ shadow: "outline" }}
    {...props}
  >
    <Box srOnly>Click to see 9 notifications</Box>
    <NotificationBadge>9</NotificationBadge>
    <Box as={FaBell} fontSize='lg' color={mode("gray.700", "white")} />
  </Center>
);

const UserAvatar = (props) => (
  <Avatar
    size='sm'
    src={props.photoUrl}
    name={props.name}
    email={props.email}
  />
);

interface UserProps {
  name: string;
  email: string;
  photoUrl: string;
}

interface ProfileMenuButtonProps extends UseMenuButtonProps, UserProps {}

const ProfileMenuButton = (props: ProfileMenuButtonProps) => {
  const { t, lang } = useTranslation("common");
  const buttonProps = useMenuButton(props);
  return (
    <Flex
      {...buttonProps}
      as='button'
      flexShrink={0}
      rounded='full'
      outline='0'
      _focus={{ shadow: "outline" }}
    >
      <Box srOnly>{t("common:header.general.openUserMenu")}</Box>
      <UserAvatar
        photoUrl={props.photoUrl}
        name={props.name}
        email={props.email}
      />
    </Flex>
  );
};

export const ProfileDropdown = (props) => {
  const { t, lang } = useTranslation("common");
  const router = useRouter();
  return (
    <Menu strategy='fixed'>
      <ProfileMenuButton
        photoUrl={props.photoUrl}
        name={props.name}
        email={props.email}
      />
      <MenuList
        rounded='md'
        shadow='lg'
        py='1'
        color={mode("gray.600", "inherit")}
        fontSize='sm'
      >
        <HStack px='3' py='4'>
          <UserAvatar
            photoUrl={props.photoUrl}
            name={props.name}
            email={props.email}
          />
          <Box lineHeight='1'>
            <Text fontWeight='semibold'>{props.name}</Text>
            <Text mt='1' fontSize='xs' color='gray.500'>
              {props.email}
            </Text>
          </Box>
        </HStack>

        <MenuItem fontWeight='medium' onClick={() => router.push("/account")}>
          {t("common:header.accountDropdown.accountSettings")}
        </MenuItem>

        <MenuItem
          fontWeight='medium'
          onClick={() =>
            window.open("https://www.thegoodlife.tools/", "_ blank")
          }
        >
          {t("common:header.accountDropdown.feedbackAndSupport")}{" "}
          <HiOutlineExternalLink style={{ margin: "0 0 0 0.5rem" }} />
        </MenuItem>

        <MenuItem
          fontWeight='medium'
          color={mode("red.500", "red.300")}
          onClick={() => props.signOut().then(() => router.push("/"))}
        >
          {t("common:signOut")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export const useMobileMenuState = () => {
  const [isMenuOpen, actions] = useBoolean();
  /**
   * Scenario: Menu is open on mobile, and user resizes to desktop/tablet viewport.
   * Result: We'll close the menu
   */
  const isMobileBreakpoint = useBreakpointValue({ base: true, lg: false });

  React.useEffect(() => {
    if (isMobileBreakpoint == false) {
      actions.off();
    }
  }, [isMobileBreakpoint, actions]);

  return { isMenuOpen, ...actions };
};

export const Header = (props) => {
  const { isMenuOpen, toggle } = useMobileMenuState();
  return props.auth ? (
    <Flex
      align='center'
      bg='white'
      boxShadow='sm'
      color='white'
      px='6'
      minH='16'
      mb={16}
    >
      <Flex
        justify='space-between'
        align='center'
        w={{ base: "100vw", md: "100vw" }}
        px={{ sm: 2, md: 24, lg: 32, xl: 48 }}
      >
        <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
        <NavMenu.Mobile isOpen={isMenuOpen} />

        {/* Desktop Logo placement */}
        <Logo
          display={{ base: "none", lg: "block" }}
          flexShrink={0}
          width={"150px"}
          paddingTop={8}
          marginEnd='4'
          onClick={() => router.push("/wins")}
          cursor={"pointer"}
        />

        {/* Desktop Navigation Menu */}
        <NavMenu.Desktop />

        {/* Mobile Logo placement */}
        <Logo
          flex={{ base: "1", lg: "0" }}
          display={{ md: "none" }}
          flexShrink={0}
          maxWidth={"150px"}
          paddingTop={8}
        />

        <HStack spacing='3'>
          <ProfileDropdown
            photoUrl={props.user.photoUrl}
            name={props.user.name}
            email={props.auth.email}
            signOut={props.signOut}
          />
        </HStack>
      </Flex>
    </Flex>
  ) : null;
};
